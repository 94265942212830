const $ = require('jquery');
const IScroll = require('iscroll');
import 'jquery-drawer';
import 'jquery-drawer/dist/css/drawer.min.css';
import 'slick-carousel'
import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
const SmoothScroll = require('smooth-scroll');

var html = $('html');

// userAgent
var _ua = (function (u) {
  return {
    isTablet: (u.indexOf("windows") != -1 && u.indexOf("touch") != -1)
      || u.indexOf("ipad") != -1
      || (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
      || (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
      || u.indexOf("kindle") != -1
      || u.indexOf("silk") != -1
      || u.indexOf("playbook") != -1,
    isMobile: (u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
      || u.indexOf("iphone") != -1
      || u.indexOf("ipod") != -1
      || (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
      || (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
      || u.indexOf("blackberry") != -1
  }
})(window.navigator.userAgent.toLowerCase());


$(function () {
  // Windows版ChromeのWebフォントを多少キレイに表示する（影を付ける）
  // Techniques for Anti-Aliasing @font-face on Windows ・ GitHub
  // https://gist.github.com/dalethedeveloper/1846552
  var shadowify = function (e) {
    var color = $(e).css('color')

    // Got Hex color?  Modify with: http://stackoverflow.com/questions/1740700/get-hex-value-rather-than-rgb-value-using-jquery
    if (color.search('rgb') == -1) {
      return;
    }
    var rgba = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/);
    $(e).css('text-shadow', '0 0 0.1px rgba(' + rgba[1] + ',' + rgba[2] + ',' + rgba[3] + ',0.5)');
    $(e).css('transform', 'rotate(0.05deg)');
  }

  if ((navigator.platform.indexOf('Win') != -1) && (navigator.userAgent.match(/chrome|opera/i)) && !(navigator.userAgent.match(/edge/i))) {
    $("p").each(function () { shadowify(this) });
    //^ Your appropriately targeted list of elements here ^
  }
});

$(function () {
  $('.slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '200px',
    // arrows: false,
    // fade: true,
    asNavFor: '.slider-nav',
    prevArrow: "<button type='button' class='slick-prev pull-left'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
    nextArrow: "<button type='button' class='slick-next pull-right'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
        }
      }
    ]
  });
  $('.slider-nav').slick({
    slidesToShow: 3,
    // slidesToScroll: 1,
    asNavFor: '.slider',
    dots: false,
    centerMode: true,
    focusOnSelect: true
  });
});


$(function () {

  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  // ドロワーメニュー
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  $('.drawer').drawer();

  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  //スムーススクロールJS
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  const scroll = new SmoothScroll('a[href*="#"]', {
    header: '[data-scroll-header]',
    updateURL: false
  });

  $(window).on('scroll', function () {
    var scroll = $(this).scrollTop();
    var footerPos = $('.footer').offset().top;
    var h = window.innerHeight;
    if (scroll == 0) {
      $('.pagetop').addClass('is-hidden');
    } else {
      $('.pagetop').removeClass('is-hidden');
    }
    if (footerPos <= scroll + h - 70) {
      $('.pagetop').addClass('is-fixed');
    } else {
      $('.pagetop').removeClass('is-fixed');
    }
  });

  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  //メガメニュー
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  $('.categoryNav__navlink--hasChild').on('click', function () {
    var target = $(this).next('.megamenu');
    $(this).toggleClass('open');
    if (target.hasClass('megamenu--open')) {
      target.removeClass('megamenu--open');
    } else {
      target.addClass('megamenu--open');
    }
    return false;
  });
});

